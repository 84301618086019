var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Opções")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { ref: "form" },
            [
              _vm._l(_vm.component_disposition_handler(), function (comp_row) {
                return _c(
                  "v-row",
                  { key: comp_row },
                  _vm._l(comp_row, function (comp_obj) {
                    return _c(
                      "v-col",
                      { key: comp_obj.ref },
                      [
                        _c(comp_obj.type, {
                          ref: comp_obj.ref,
                          refInFor: true,
                          tag: "component",
                          attrs: {
                            index: _vm
                              .get_component_object_array()
                              .indexOf(comp_obj),
                            render: comp_obj.render,
                            "v-model": comp_obj.vModel,
                          },
                          on: {
                            validation: _vm.component_object_array_handler,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "report-btn-container" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "report-btn",
                            attrs: {
                              large: "",
                              color: "secondary",
                              disabled: _vm.submit_button_disable,
                              loading: _vm.requesting,
                            },
                            on: { click: _vm.submit_report },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                            _vm._v(" GERAR NOVO RELATÓRIO "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.errorDialog,
            callback: function ($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 red white--text" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { left: "" } },
                    [_vm._v("mdi-alert")]
                  ),
                  _vm._v(" Algo deu errado "),
                ],
                1
              ),
              _c("br"),
              _c("v-card-text", { staticClass: "text-h6 lighten-2" }, [
                _vm._v(
                  " Tente novamente mais tarde ou entre em contato com o administrador "
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-2",
                      attrs: { text: "", id: "IdBtnClose" },
                      on: {
                        click: function ($event) {
                          _vm.errorDialog = false
                        },
                      },
                    },
                    [_vm._v(" fechar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
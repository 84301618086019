var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-menu",
        {
          ref: "menu1",
          attrs: {
            "close-on-content-click": false,
            "return-value": _vm.vModel.start_date,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "290px",
            "min-width": "auto",
          },
          on: {
            "update:returnValue": function ($event) {
              return _vm.$set(_vm.vModel, "start_date", $event)
            },
            "update:return-value": function ($event) {
              return _vm.$set(_vm.vModel, "start_date", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-3",
                          attrs: {
                            label: "De",
                            "prepend-icon": "mdi-calendar",
                            readonly: "",
                            dense: "",
                          },
                          on: { change: _vm.validation },
                          model: {
                            value: _vm.vModel.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.vModel, "start_date", $$v)
                            },
                            expression: "vModel.start_date",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu1,
            callback: function ($$v) {
              _vm.menu1 = $$v
            },
            expression: "menu1",
          },
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: {
                dense: "",
                type: "month",
                "no-title": "",
                scrollable: "",
              },
              model: {
                value: _vm.vModel.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.vModel, "start_date", $$v)
                },
                expression: "vModel.start_date",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.menu1 = false
                    },
                  },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.$refs.menu1.save(_vm.vModel.start_date)
                      _vm.validation()
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          ref: "menu2",
          attrs: {
            "close-on-content-click": false,
            "return-value": _vm.vModel.end_date,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "290px",
            "min-width": "auto",
          },
          on: {
            "update:returnValue": function ($event) {
              return _vm.$set(_vm.vModel, "end_date", $event)
            },
            "update:return-value": function ($event) {
              return _vm.$set(_vm.vModel, "end_date", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-5",
                          attrs: {
                            label: "Até",
                            "prepend-icon": "mdi-calendar",
                            readonly: "",
                            dense: "",
                          },
                          on: { change: _vm.validation },
                          model: {
                            value: _vm.vModel.end_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.vModel, "end_date", $$v)
                            },
                            expression: "vModel.end_date",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu2,
            callback: function ($$v) {
              _vm.menu2 = $$v
            },
            expression: "menu2",
          },
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: { type: "month", "no-title": "", scrollable: "" },
              model: {
                value: _vm.vModel.end_date,
                callback: function ($$v) {
                  _vm.$set(_vm.vModel, "end_date", $$v)
                },
                expression: "vModel.end_date",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.menu2 = false
                    },
                  },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.$refs.menu2.save(_vm.vModel.end_date)
                      _vm.validation()
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c(
            "v-spacer",
            [
              _vm.render.last5y_button === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-5",
                      on: {
                        click: function ($event) {
                          _vm.set_start_date(
                            _vm.date_minus_5_years(_vm.vModel.end_date)
                          )
                          _vm.validation()
                        },
                      },
                    },
                    [_vm._v("5 Anos ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      items: _vm.render.list_of_options,
      label: _vm.render.initial_label,
    },
    on: { change: _vm.validation },
    model: {
      value: _vm.vModel.selection,
      callback: function ($$v) {
        _vm.$set(_vm.vModel, "selection", $$v)
      },
      expression: "vModel.selection",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-autocomplete
      v-if="userInfo !== undefined"
      ref="inputfield"
      v-model="vModel.input"
      :items="userInfo.companies"
      label="Empresa"
      dense
      clearable
      append-icon="mdi-chevron-down"
      hide-details
      persistent-placeholder
      @change="validation()"
      @click:append="validation()"
    >
    </v-autocomplete>
    <label v-else>Carregando...</label>
  </div>
</template>

<script>
import AdminServices from '@/services/adminServices';
import UserService from '@/services/userService';

export default {
  name: 'CnpjRootComponent',
  // settings:
  //  no settings required
  //
  //  actions:
  //    append_body:
  //      appends vModel.input as atribute to given object
  //      default key: cnpj
  //
  props: ['index', 'render', 'vModel'],
  required_settings: [],
  optional_settings: ['custom_body_atribute_key_cnpj'],
  parse_settings(settings) {
    this.required_settings.forEach((setting) => {
      if (!(setting in settings)) {
        throw new SyntaxError('All required_settings given');
      }
    });

    return JSON.parse(
      JSON.stringify({
        render: {
          body_atribute_key_cnpj:
            typeof settings.custom_body_atribute_key_cnpj === 'string'
              ? settings.custom_body_atribute_key_cnpj
              : 'cnpj',
        },

        vModel: {
          input: null,
          valid: false,
        },

        width: 1,
      }),
    );
  },

  data() {
    return {
      request_increment_methods: {
        append_body: this.append_body,
      },

      userInfo: undefined,
    };
  },

  async mounted() {
    this.userInfo = await this.getUserInfo();
  },

  methods: {
    async getUserInfo() {
      let { data } = await UserService.getUser();

      if (data) {
        let establishments = [];
        let companies = [];
        const UserEstablishmentsInfo = await AdminServices.userEstablishment.get(data.id);
        UserEstablishmentsInfo.data.forEach((obj) => {
          const establishment = obj.establishment.details.cnpj;
          establishments.push(establishment);
          const root =
            establishment.substring(0, 2) + '.' + establishment.substring(2, 5) + '.' + establishment.substring(5, 8);
          if (!companies.includes(root)) {
            companies.push(root);
          }
        });

        return {
          id: data.id,
          username: data.username,
          emailAddress: data.emailAddress,
          authorities: data.authorities,
          establishments: establishments,
          companies: companies,
          UserEstablishmentsInfo: UserEstablishmentsInfo,
        };
      }
    },

    async validation() {
      this.vModel.valid = this.userInfo.companies.includes(this.vModel.input);

      this.$emit('validation', {
        type: 'validation',
        valid: this.vModel.valid,
        index: this.index,
      });
    },

    // component output methods
    request_increment_handler(request_obj, func) {
      if (func in this.request_increment_methods) {
        return this.request_increment_methods[func](request_obj);
      } else {
        throw new SyntaxError('Called function doesnt exist');
      }
    },

    append_body(request_obj) {
      request_obj.body[this.render.body_atribute_key_cnpj] = this.vModel.input;
      return request_obj;
    },
  },
};
</script>

<style scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.userInfo !== undefined
        ? _c("v-autocomplete", {
            ref: "inputfield",
            attrs: {
              items: _vm.userInfo.companies,
              label: "Empresa",
              dense: "",
              clearable: "",
              "append-icon": "mdi-chevron-down",
              "hide-details": "",
              "persistent-placeholder": "",
            },
            on: {
              change: function ($event) {
                return _vm.validation()
              },
              "click:append": function ($event) {
                return _vm.validation()
              },
            },
            model: {
              value: _vm.vModel.input,
              callback: function ($$v) {
                _vm.$set(_vm.vModel, "input", $$v)
              },
              expression: "vModel.input",
            },
          })
        : _c("label", [_vm._v("Carregando...")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
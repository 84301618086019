<template>
  <v-row>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      :return-value.sync="vModel.start_date"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="vModel.start_date"
          class="ml-3"
          label="De"
          prepend-icon="mdi-calendar"
          readonly
          dense
          v-bind="attrs"
          v-on="on"
          @change="validation"
        ></v-text-field>
      </template>
      <v-date-picker dense v-model="vModel.start_date" type="month" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu1 = false"> Cancelar</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.menu1.save(vModel.start_date);
            validation();
          "
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      ref="menu2"
      v-model="menu2"
      :close-on-content-click="false"
      :return-value.sync="vModel.end_date"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="vModel.end_date"
          class="ml-5"
          label="Até"
          prepend-icon="mdi-calendar"
          readonly
          dense
          v-bind="attrs"
          v-on="on"
          @change="validation"
        ></v-text-field>
      </template>
      <v-date-picker v-model="vModel.end_date" type="month" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu2 = false"> Cancelar</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.menu2.save(vModel.end_date);
            validation();
          "
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-col>
      <v-spacer>
        <v-btn
          v-if="render.last5y_button === true"
          class="ml-5"
          @click="
            set_start_date(date_minus_5_years(vModel.end_date));
            validation();
          "
          >5 Anos
        </v-btn>
      </v-spacer>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CompPeriodComponent',
  // settings:
  //  optional:
  //    - last5y_button:
  //      on click changes start_date to 60 months earlier than end_date
  //      type: bool
  //      default: false
  //
  //    - start_date_validation:
  //      adds or removes start_date from validation,
  //      if removed, request will have this setting as null
  //      type: bool
  //      default: true
  //
  //    - end_date_validation:
  //      adds or removes end_date from validation,
  //      if removed, request will have this setting as null
  //      type: bool
  //      default: true
  //
  //    - end_date_today:
  //      preselects today as end_date if true
  //      type: bool
  //      default: false
  //
  //    - custom_body_atribute_key_start_date:
  //      if informed, will use the name the string as start_date on body
  //      type: string
  //
  //    - custom_body_atribute_key_end_date:
  //      if informed, will use the name the string as end_date on body
  //      type: bool
  //      type: string
  //
  //  actions:
  //    append_body:
  //      appends vModel.end_date and vModel.start_date as atributes to given object
  //      default keys: end_date and start_date
  //

  props: ['index', 'render', 'vModel'],
  required_settings: [],
  optional_settings: [
    'last5y_button',
    'start_date_validation',
    'end_date_validation',
    'end_date_today',
    'custom_body_atribute_key_start_date',
    'custom_body_atribute_key_end_date',
  ],

  parse_settings(settings) {
    this.required_settings.forEach((setting) => {
      if (!(setting in settings)) {
        throw new SyntaxError('All required_settings given');
      }
    });

    let settings_out = JSON.parse(
      JSON.stringify({
        render: {
          last5y_button: settings.last5y_button !== undefined ? settings.last5y_button : false,
          start_date_validation: settings.start_date_validation !== undefined ? settings.start_date_validation : true,
          end_date_validation: settings.end_date_validation !== undefined ? settings.end_date_validation : true,
          end_date_today: settings.end_date_today !== undefined ? settings.end_date_today : false,
          body_atribute_key_start_date:
            typeof settings.custom_body_atribute_key_start_date === 'string'
              ? settings.custom_body_atribute_key_start_date
              : 'start_date',
          body_atribute_key_end_date:
            typeof settings.custom_body_atribute_key_end_date === 'string'
              ? settings.custom_body_atribute_key_end_date
              : 'end_date',
        },

        vModel: {
          start_date: null,
          end_date: null,
          valid:
            (settings.start_date_validation === false || settings.start_date_validation === undefined) &&
            (settings.end_date_validation === false ||
              settings.end_date_validation === undefined ||
              settings.end_date_today === true ||
              settings.end_date_today === undefined),
        },

        width: settings.last5y_button === true ? 3 : 2,
      }),
    );

    if (settings.end_date_today === true) {
      settings_out.vModel.end_date = this.methods.now_date();
    }

    return settings_out;
  },

  data() {
    return {
      menu1: false,
      menu2: false,
      request_increment_methods: {
        append_body: this.append_body,
      },
    };
  },

  methods: {
    validation() {
      let valid_var = true;
      [
        {
          date: this.vModel.start_date,
          validation_enabled: this.render.start_date_validation,
        },
        {
          date: this.vModel.start_date,
          validation_enabled: this.render.end_date_validation,
        },
      ].forEach((obj) => {
        if (obj.validation_enabled === true && moment(obj.date, 'YYYY-MM', true).isValid() === false) {
          valid_var = false;
        }
      });

      if (this.vModel.start_date > this.vModel.end_date) {
        valid_var = false;
      }

      this.vModel.valid = valid_var;
      this.$emit('validation', {
        type: 'validation',
        valid: this.vModel.valid,
        index: this.index,
      });
    },

    now_date() {
      return new Date().toISOString().substr(0, 7);
    },

    date_minus_5_years(date) {
      let new_date = new Date(date);
      new_date.setMonth(new_date.getMonth() - 60);
      return new_date.toISOString().substr(0, 7);
    },

    set_start_date(dateStr) {
      this.vModel.start_date = dateStr;
    },

    request_increment_handler(request_obj, func) {
      if (func in this.request_increment_methods) {
        return this.request_increment_methods[func](request_obj);
      } else {
        throw new SyntaxError('Called function doesnt exist');
      }
    },

    append_body(request_obj) {
      request_obj.body[this.render.body_atribute_key_end_date] =
        this.vModel.end_date.slice(5, 7) + '-' + this.vModel.end_date.slice(0, 4);
      request_obj.body[this.render.body_atribute_key_start_date] =
        this.vModel.start_date.slice(5, 7) + '-' + this.vModel.start_date.slice(0, 4);
      return request_obj;
    },
  },
};
</script>

<style scoped></style>

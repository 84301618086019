<template>
  <v-select
    v-model="vModel.selection"
    :items="render.list_of_options"
    :label="render.initial_label"
    @change="validation"
  >
  </v-select>
</template>

<script>
export default {
  name: 'SelectComponent',
  // settings:
  //  required:
  //    - list_of_options:
  //      list of dropdown select options
  //      type: array
  //
  //    - request_inputs:
  //      list of request_inputs CORRESPONDING TO list_of_options INDEX
  //      type: array
  //
  //    - initial_label:
  //      initial label dropdown select options
  //      may be one of the options
  //      type: string

  //  actions:
  //    append_url:
  //      appends vModel.selection to given string
  //

  props: ['index', 'render', 'vModel'],
  required_settings: ['list_of_options', 'initial_label'],
  optional_settings: [],
  parse_settings(settings) {
    this.required_settings.forEach((setting) => {
      if (!(setting in settings)) {
        throw new SyntaxError('All required_settings given');
      }
    });
    return JSON.parse(
      JSON.stringify({
        render: {
          list_of_options: settings.list_of_options,
          request_inputs: settings.request_inputs,
          initial_label: settings.initial_label,
        },

        vModel: {
          selection: null,
          valid: false,
        },

        width: 1,
      }),
    );
  },

  data() {
    return {
      request_increment_methods: {
        append_url: this.append_url,
      },
    };
  },

  methods: {
    validation() {
      this.vModel.valid = !!this.render.list_of_options.includes(this.vModel.selection);
      this.$emit('validation', {
        type: 'validation',
        valid: this.vModel.valid,
        index: this.index,
      });
    },

    request_increment_handler(request_obj, func) {
      if (func in this.request_increment_methods) {
        return this.request_increment_methods[func](request_obj);
      } else {
        throw new SyntaxError('Called function doesnt exist');
      }
    },

    append_url(request_obj) {
      request_obj.url =
        request_obj.url + this.render.request_inputs[this.render.list_of_options.indexOf(this.vModel.selection)];
      return request_obj;
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-card>
    <v-card-title>Opções</v-card-title>
    <v-card-text>
      <v-container ref="form">
        <v-row v-for="comp_row in component_disposition_handler()" :key="comp_row">
          <v-col v-for="comp_obj in comp_row" :key="comp_obj.ref">
            <component
              :is="comp_obj.type"
              :ref="comp_obj.ref"
              :index="get_component_object_array().indexOf(comp_obj)"
              :render="comp_obj.render"
              :v-model="comp_obj.vModel"
              @validation="component_object_array_handler"
            ></component>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="report-btn-container">
              <v-btn
                large
                color="secondary"
                class="report-btn"
                :disabled="submit_button_disable"
                :loading="requesting"
                @click="submit_report"
              >
                <v-icon left>mdi-plus</v-icon>
                GERAR NOVO RELATÓRIO
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 red white--text">
          <v-icon left class="white--text">mdi-alert</v-icon>
          Algo deu errado
        </v-card-title>
        <br />
        <v-card-text class="text-h6 lighten-2">
          Tente novamente mais tarde ou entre em contato com o administrador
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text class="grey lighten-2" @click="errorDialog = false" id='IdBtnClose'> fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CnpjRootComponent from '@/router/views/pis-cofins/components/CnpjRootComponent';
import CompPeriodComponent from '@/router/views/pis-cofins/components/CompPeriodComponent';
import SelectComponent from '@/router/views/pis-cofins/components/SelectComponent';
import pisCofinsService from '@/services/pisCofinsService.js';

import ReportsSettings from '../report_settings.json';

export default {
  name: 'DinamicReportWidget',
  components: {
    SelectComponent,
    CnpjRootComponent,
    CompPeriodComponent,
  },

  data() {
    return {
      reports_settings: ReportsSettings.reports,
      report_types: {
        select_one: SelectComponent,
        cnpj_root_input: CnpjRootComponent,
        period_input: CompPeriodComponent,
      },

      option_phases: [],
      report: '',
      component_object_array: [],
      submit_button_disable: true,
      requesting: false,

      errorDialog: false,
    };
  },

  methods: {
    component_disposition_handler() {
      const components = this.get_component_object_array();

      let disposition = {};
      let row = 0;
      let cwidth = 0;

      disposition[row] = [];

      components.forEach((component) => {
        if (cwidth + component.width > 3) {
          row = row + 1;
          disposition[row] = [];
        }
        disposition[row].push(component);
        cwidth = cwidth + component.width;
      });
      return disposition;
    },

    get_component_object_array() {
      if (this.component_object_array.length === 0) {
        this.component_object_array_handler();
      }
      return this.component_object_array;
    },

    component_object_array_handler(event) {
      if (event !== undefined) {
        if (event.type === 'validation') {
          // handles changes in report choice, changing option_phases list and deleting old options
          if (event.valid === true && event.index === 0) {
            const new_option_phases = this.reports_settings.find((obj) => {
              return obj.name === this.component_object_array[0].vModel.selection;
            }).option_phases;

            if (new_option_phases !== this.option_phases) {
              this.component_object_array = [this.component_object_array[0]];
            }
            this.option_phases = new_option_phases;
          }

          // if validation is not from the last option
          // remove all options after that, and call its validation
          if (event.index < this.component_object_array.length - 1) {
            this.component_object_array.splice(event.index + 1, this.component_object_array.length - 1 - event.index);
          }

          // if last rendered option is valid and not the last overall option
          // adds the next option, based on the index of the current one and in option_phases
          if (
            event.valid === true &&
            event.index === this.component_object_array.length - 1 &&
            event.index !== this.option_phases.length
          ) {
            const comp_type = this.option_phases[event.index].type;
            const settings = this.report_types[comp_type].parse_settings(this.option_phases[event.index].settings);

            this.component_object_array.push({
              type: this.report_types[comp_type],
              ref: 'comp_obj_li_item_' + this.component_object_array.length,
              render: settings.render,
              vModel: settings.vModel,
              width: settings.width,
            });
          }

          // enables submit button if all valid
          let enable_submit = true;
          this.component_object_array.forEach((obj) => {
            if (obj.vModel.valid === false) {
              enable_submit = false;
            }
          });
          this.submit_button_disable = !enable_submit;
        }
      }

      // empty array -> add report choice
      if (this.component_object_array.length === 0) {
        this.component_object_array.push({
          type: SelectComponent,
          ref: 'comp_obj_li_item_' + 0,
          render: {
            list_of_options: this.get_reports_list(false),
            initial_label: 'Relatórios',
          },

          vModel: {
            selection: undefined,
            valid: false,
          },

          width: 1,
        });
      }
    },

    get_reports_list(event) {
      if (event) {
        event.preventDefault();
      }
      let reports_li = [];
      this.reports_settings.forEach((report_setting) => {
        reports_li.push(report_setting.name);
      });
      return reports_li;
    },

    async submit_report(event) {
      this.requesting = true;
      if (event) {
        event.preventDefault();
      }
      let request_obj = {
        url: JSON.parse(
          JSON.stringify(
            this.reports_settings.find((obj) => {
              return obj.name === this.component_object_array[0].vModel.selection;
            }).base_url,
          ),
        ),

        body: {},
      };

      this.option_phases.forEach((phase, index) => {
        const comp_obj = this.component_object_array[index + 1];
        request_obj = this.$refs[comp_obj.ref][0].request_increment_handler(request_obj, phase.action);
      });

      let response = await pisCofinsService.reports_process.post(request_obj.url, request_obj.body);
      if (response.status !== 201) {
        this.errorDialog = true;
      }
      this.requesting = false;
      this.$emit('report-requested');
      this.clear_report_options();
    },

    clear_report_options() {
      this.option_phases = [];
      this.report = '';
      this.component_object_array = [];
      this.submit_button_disable = true;
    },
  },
};
</script>

<style scoped>
.report-btn-container {
  display: flex;
  justify-content: center;
}

.report-btn {
  margin: 10px;
}
</style>
